<template>
    <div class="add-scenario">
        <div class="d-flex flex-column home-header">
            <p @click="$router.push({name: 'Scenarios'})" class="d-flex align-center ma-0 text-h4 primary--text font-weight-bold">{{$t('scenarios')}} <span class="mx-3">></span> <span class="ma-0 text-h6 primary--text font-weight-light">{{$t('createScenario')}} </span></p>
            <!-- <p class="justify-center ma-0 text-center text-h5 primary--text font-weight-bold">{{$t('createYourScenario')}}</p> -->
        </div>
        <v-divider class="mx-5 my-5"></v-divider>
        <v-form class="d-flex flex-column" ref="addScenarioForm" v-model="valid" lazy-validation>
            <div class="d-flex flex-column input-group">
                <p class="mb-1 darkGrey--text text-body-2 font-weight-bold">{{$t("campaignScenarioLinkNewScenarioNameLabel")}}</p>
                <v-text-field
                    v-model="name"
                    class="custom-input rounded-lg font-weight-bold text-body-2"
                    filled
                    :rules="[rules.notEmpty]" 
                    required
                    dense
                    hide-details
                ></v-text-field>
            </div>

            <div class="my-2 d-flex align-center full-width">
                <v-spacer></v-spacer>
                <CustomButton @click="createScenario">{{$t('createScenario')}}</CustomButton>
            </div>

            
        </v-form>
    </div>
</template>

<script>
const ScenarioService = require("@/services/ScenarioService");
export default {
    name: 'AddScenario',
    data: (vm) => {
        return {
            valid: true,
            name: null,
            rules: {
                notEmpty: value => vm.isNotNull(value) ? true : vm.$t('emptyFieldError')
            },
        }
    },
    created() {
        this.updatePageInfo(this.$route.meta)
    },
    methods: {
        createScenario() {
            if (this.$refs.addScenarioForm.validate()) {
                ScenarioService.add(this.name).then(({status, data}) => {
                    if (status === 200 && !data.error) {
                        this.$router.push({name: 'Screens', query: {scenarioId: data.scenarioId}})
                    }
                })
            }
        }
    }
}
</script>
<style scoped>

    .custom-input>>>.v-input__slot {
        margin: 0 !important;
    }

    .custom-input>>>.v-input__slot::before {
        border: none !important;
    }

    .custom-input>>>.v-input__slot::after {
        border: none !important;
    }

    .custom-input>>>.v-input__slot {
        border: none !important;
    }
</style>